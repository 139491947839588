export default {
    // API_BASE_URL: 'https://apipreprod.elhadepilacionlaser.com',
    API_BASE_URL: 'https://api.elhadepilacionlaser.com',
    // API_BASE_URL: 'http://api.elha.local/app_dev.php',
    API_BASE_SUFFIX: '/v2',
    ACCESS_TOKEN: 'MDkxOWI0YjI5MDlhMWYyYmZjNmI1YWIwZTJmOGYwOWJlMDFlNWRiZjM3MGFjODRiYThjZDk5MjA2NzA5NDRhZA',
    URL_SPAIN: 'primerasesion.elhadepilacionlaser.com',
    URL_ITALY: 'primasessione.centrielha.it',
    IDS_ORDEN_ESP_M: [1322, 1186, 1742, 1188, 29621],
    IDS_ORDEN_ESP_F: [1322, 1186, 1742, 1188, 29621],
    IDS_ORDEN_IT_M: [1322, 1186, 1742, 1188, 29621],
    IDS_ORDEN_IT_F: [1322, 1186, 1742, 1188, 29621],
    CENTRO_BERGOGNONE: 1954
}
